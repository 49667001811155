/* Overall container for FAQ page */
.faq-container {
  padding: 20px;
  text-align: center;
}

.page-title {
  font-size: 2rem; /* Adjust the title size */
  font-weight: bold;
  margin: 40px 0; /* Add margin top and bottom for more space around the title */
  padding: 20px; /* Add padding around the title */
}

.faq-list {
  display: flex;
  flex-direction: column;
  gap: 20px; /* Space between each FAQ item */
  align-items: center;
}

.faq-item {
  display: flex;
  justify-content: space-between;
  gap: 20px; /* Space between question and answer */
  background-color: #f9f9f9;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  width: 80%;
  max-width: 800px; /* Ensure the items don't stretch too wide */
  text-align: left; /* Align text left */
}

.faq-question-container {
  display: flex;
  align-items: center;
  gap: 10px;
  flex: 2; /* Ensures the question takes up available space */
}

.faq-icon {
  font-size: 1.5rem; /* Size of the icon */
  color: #070627; /* Icon color */
}

.faq-question {
  font-size: 1.2rem;
  font-weight: bold;
  margin: 0;
  flex: 10; /* Ensures the question text takes more space */
}

.faq-answer {
  font-size: 1rem;
  line-height: 1.5;
  color: #555;
  flex: 3; /* Ensures the answer text takes up more space than the question */
}
