.event-detail-container {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .event-detail-container h1 {
    font-size: 2em;
    margin-bottom: 10px;
  }
  
  .event-detail-container p {
    font-size: 1.2em;
    margin-bottom: 10px;
  }
  
  .event-detail-container strong {
    font-weight: bold;
  }
  /* eventdetail.css */

/* Style for the photo container */
.event-photo-container {
    text-align: center; /* Center the photo */
    margin-bottom: 20px;
  }
  
  /* Style for the photo */
  .event-photo {
    width: 100%; /* Make the photo responsive */
    max-width: 600px; /* Limit the maximum size */
    height: auto; /* Maintain aspect ratio */
    border-radius: 8px; /* Optional: add rounded corners */
  }
  