.catalog-title {
    text-align: center;
    font-size: 2rem; /* Large and bold font size */
    font-weight: 700; /* Bold text */
    margin-top: 0; /* Adjust spacing from the header */
    margin-bottom: 1.5rem; /* Spacing below the title */
    color: #13273a; /* Dark modern color */
    text-decoration: none; /* Removes any underline */
    background: linear-gradient(to right, #555c55, #2196F3); /* Gradient effect */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; /* Gradient applies to text only */
    letter-spacing: 1.5px; /* Add slight letter spacing */
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2); /* Soft shadow effect */
    border: none;
}

.training-list {
    margin-top: 20px; /* Add top margin for spacing */
}
