.processus-certification-container {
  max-width: 800px; /* Centering the container */
  margin: 40px auto; /* Adding margin for spacing */
  padding: 30px; /* Increased padding to give more space for the content */
  background-color: #f9f9f9; /* Light background color */
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  font-size: 16px; /* Increased base font size */
}

.process-steps {
  display: flex; /* Flex layout for steps */
  align-items: center; /* Centering items vertically */
  justify-content: space-evenly; /* Even space distribution between steps */
  gap: 20px; /* Space between steps */
  flex-wrap: nowrap; /* Prevent wrapping to maintain a single line */
  margin-top: 30px; /* Space between the steps and the content above */
}

.step {
  display: flex; /* Flex layout for step items */
  align-items: center; /* Centering items vertically */
  justify-content: center; /* Centering horizontally */
  flex-direction: column; /* Column direction for circle and description */
  text-align: center; /* Centering text */
  font-size: 14px; /* Slightly bigger font size for description text */
  flex: 1; /* Allow steps to grow to fill space */
  min-width: 120px; /* Ensure the steps have a minimum width */
  max-width: 180px; /* Limit the maximum width */
}

.circle {
  width: 45px; /* Increased circle size */
  height: 45px; /* Increased circle size */
  background-color: #007BFF; /* Circle color */
  color: white; /* Text color inside circle */
  border-radius: 50%; /* Making it a circle */
  display: flex; /* Flex layout for centering text */
  align-items: center; /* Centering text vertically */
  justify-content: center; /* Centering text horizontally */
  font-weight: bold; /* Bold text */
  margin-bottom: 10px; /* Space below circle */
  font-size: 18px; /* Larger text inside the circle */
}

.arrow {
  font-size: 22px; /* Larger arrow size */
  color: #007BFF; /* Arrow color */
}

.step-description h3 {
  font-size: 16px; /* Increased title size */
  margin-bottom: 8px; /* Space below the title */
}

.step-description {
  max-width: 200px; /* Limit the description width */
  margin-top: 8px; /* Space between circle and description */
  font-size: 12px; /* Slightly larger font size for the text below the circle */
}

.footer-note {
  margin-top: 25px; /* Increased margin above footer note */
  font-size: 14px; /* Larger font size for footer note */
  color: #555; /* Color for footer note */
  text-align: center; /* Centering the footer note */
}

.certification-image-container {
  text-align: center; /* Center the image */
  margin-bottom: 30px; /* Increased space between the image and the text */
}

.certification-image {
  max-width: 100%; /* Ensure the image scales with the container */
  height: auto; /* Maintain aspect ratio */
  border-radius: 8px; /* Optional: Add rounded corners */
}

.page-title {
  text-align: center;
  font-size: 1.5rem; /* Adjusted font size */
  font-weight: bold;
  margin-bottom: 20px;
  color: #333;
}
