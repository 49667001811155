.testimonial-slider-container {
    padding: 40px 0; /* Space above and below the slider */
    background-color: #f8f9fa; /* Light background color for contrast */
}

.testimonial-item {
    background: white; /* White background for each testimonial item */
    border-radius: 15px; /* Rounded corners */
    padding: 10px; /* Inner padding */
   
    text-align: center; /* Center-align text */
    transition: transform 0.3s; /* Smooth scaling effect on hover */
}

.testimonial-item:hover {
    transform: translateY(-5px); /* Lift effect on hover */
}

.testimonial-logo {
    width: 120px; /* Width for logo image */
    height: 120px; /* Height for logo image */
    object-fit: cover; /* Ensures the image doesn't stretch */
    border-radius: 10px; /* Rounded corners for the logos */
   
    transition: transform 0.1s; /* Smooth scale on hover */
}

.testimonial-logo:hover {
    transform: scale(1.05); /* Logo scales up slightly when hovered */
}

h1 {
    font-size: 2.5em; /* Larger heading for the slider */
    color: #333; /* Darker color for text */
    margin-bottom: 40px; /* Space below the heading */
}

.owl-carousel .owl-item {
    display: flex;
    justify-content: center; /* Centers logos in the item */
}

.owl-carousel .owl-nav {
    display: none; /* Hide next/prev buttons */
}

.owl-carousel .owl-dots {
    display: none; /* Hide pagination dots */
}
.testimonial-item {
    margin: 0 15px; /* Adds space between each item */
    display: flex;
    justify-content: center;
}
.testimonial-logo {
    width: 100%; /* Make logos responsive */
    max-width: 150px; /* Adjust the size of the logos */
    height: auto;
    margin: 0 auto; /* Center logos */
}
/* Add spacing between items */
.testimonial-item {
    margin: 0 15px; /* Adjust the spacing between logos */
    display: flex;
    justify-content: center;
}

/* Optionally adjust the size of the logos */
.testimonial-logo {
    width: 100%; /* Make logos responsive */
    max-width: 150px; /* Adjust the size of the logos */
    height: auto;
    margin: 0 auto; /* Center logos */
}
.nav-btn {
    background-color: #f8f9fa;
    padding: 10px;
    border-radius: 50%;
    cursor: pointer;
}

.prev-slide {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    font-size: 24px;
}

.next-slide {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    font-size: 24px;
}
.testimonial-column-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 50px; /* Space between the two columns */
  }
  
  .testimonial-slide {
    display: flex;
    flex-direction: column;
    gap: 20px; /* Space between the two rows */
  }
  
  .testimonial-row {
    display: flex;
    justify-content: space-around; /* Even spacing for logos */
    gap: 20px; /* Space between logos */
  }
  
  .testimonial-item {
    flex: 1;
    max-width: 150px; /* Adjust size of logos */
    text-align: center;
  }
  
  .testimonial-logo {
    max-width: 100px;
    max-height: 100px;
    object-fit: contain;
  }
  
  