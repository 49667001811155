.galerie-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .galerie-slider {
    margin-top: 20px;
  }
  
  .galerie-item img {
    width: 100%; /* Make the images responsive */
    height: auto; /* Maintain aspect ratio */
  }
  