/* Style for the course section */
.course-item {
    background-color: #ffffff;
    border-radius: 15px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    padding: 25px;
    text-align: center;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.course-item img {
    max-width: 100%;
    border-radius: 8px;
    margin-bottom: 15px;
}

.course-item h4 {
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 10px;
}

.course-item p {
    font-size: 1.1rem;
    color: #555;
    margin-bottom: 20px;
}

/* Center the "Join" button */
.btn-container {
    display: flex;
    justify-content: center;
    margin-top: 15px;
}

/* Style for the "Join" button */
.btn-primary {
    background-color: #3498db; /* Blue color */
    color: #fff;
    padding: 10px 25px;
    font-size: 1.1rem;
    border-radius: 25px;
    text-decoration: none;
    transition: background-color 0.3s ease, transform 0.3s ease;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.btn-primary:hover {
    background-color: #2980b9; /* Darker blue on hover */
    transform: translateY(-3px); /* Slight lift on hover */
}

.course-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
}
.btn-container .btn {
    background-color: #007bff; /* Blue color */
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 5px;
    text-decoration: none;
    transition: background-color 0.3s ease;
}

.btn-container .btn:hover {
    background-color: #0056b3; /* Darker blue on hover */
    color: #fff;
}
