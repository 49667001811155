// SearchCertification.scss

$primary-color: #6186ae; // Primary color for buttons and headers
$secondary-color: #f8f9fa; // Light background color
$highlight-color: #d9edf7; // Color for table row highlighting
$font-family: 'Arial', sans-serif;
$border-radius: 8px;
$shadow: rgba(0, 0, 0, 0.2); // Shadow color

// Container styling
.search-container {
    background-color: $secondary-color;
    padding: 40px; // Keep padding for adequate spacing
    border-radius: $border-radius;
    box-shadow: 0 4px 20px $shadow; // Shadow for depth
    max-width: 1000px; // Increased container width
    margin: 60px auto; // Keep centered alignment
}

// Header styling
h2 {
    font-family: $font-family;
    color: $primary-color;
    margin-bottom: 30px;
    text-align: center; // Centered header
    font-weight: bold;
    font-size: 2rem; // Increased font size for clarity
}

// Input styling
.search-input {
    width: 45%; // Keep input width
    margin-right: 15px; // Space between inputs in the row
    margin-bottom: 20px; // Add spacing below inputs for vertical alignment
    padding: 15px; // Comfortable padding
    border: 1px solid #ccc; // Light border
    border-radius: $border-radius;
    transition: border-color 0.3s ease;
    font-size: 1.2rem; // Readable font size

    &:focus {
        outline: none;
        border-color: $primary-color;
        box-shadow: 0 0 5px $primary-color;
    }
}

// Button styling
.search-button {
    width: 20%; // Slightly increase button width for consistency
    margin-top: 20px; // Space above the button
    background-color: $primary-color;
    color: white;
    border: none;
    border-radius: $border-radius;
    padding: 15px; // Comfortable button padding
    cursor: pointer;
    font-size: 1.2rem;
    transition: background-color 0.3s, transform 0.2s;

    &:hover {
        background-color: darken($primary-color, 10%);
        transform: translateY(-2px);
    }

    &:active {
        transform: translateY(0);
    }
}

.search-container form {
    display: flex;
    flex-wrap: wrap; // Allow wrapping on smaller screens
    gap: 20px; // Add space between all form elements
    justify-content: space-between; // Spread out inputs and button
}
// Table styling
.certification-table {
    margin-top: 30px;
    width: 100%;
    border-collapse: collapse; // Collapse borders

    th {
        background-color: $primary-color;
        color: white;
        text-align: center;
        padding: 15px; // Increased padding for table header
        font-size: 1.5rem; // Increased font size for headers
        border-bottom: 3px solid darken($primary-color, 15%); // Bottom border for header
    }

    td {
        text-align: center;
        padding: 12px; // Increased padding for table cells
        border-bottom: 1px solid #ddd; // Light bottom border for cells

        &:nth-of-type(odd) {
            background-color: $highlight-color; // Alternate row color
        }
    }
}

// Responsive adjustments
@media (max-width: 600px) {
    .search-input {
        width: 100%; // Full width for smaller screens
        margin: 10px 0;
    }

    .search-button {
        width: 100%; // Full width for smaller screens
        margin-top: 10px;
    }
}