/* Button with bright text color */
.btn-bright-text {
  color: rgb(26, 23, 59); /* Bright text color */
  text-transform: uppercase;
  font-weight: bold;
  display: inline-flex; /* Keeps text inline */
  align-items: center;
  justify-content: center; /* Centers text */
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  text-decoration: none; /* Removes underline */
}

/* Hover effect for the button */
.btn-bright-text:hover {
  color: white; /* White text on hover */
  background-color: white; /* Background changes to white */
  border-radius: 5px; /* Rounded corners */
  padding: 5px 10px; /* Padding for spacing */
}

/* Icon inside the button */
.btn-bright-text i {
  margin-right: 8px; /* Space between icon and text */
}

/* Button text */
.btn-bright-text span {
  display: block; /* Makes text take full width */
  text-align: center; /* Centers wrapped text */
}

/* Logo background */
.logo {
  width: 200px; /* Increase the logo size */
  height: 100%; /* Logo fills the header height */
  background-image: url('../Images/logo.png');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  display: inline-block;
  border: none; /* Remove border */
  box-shadow: none; /* Remove shadow */
}

/* Dropdown menu */
.dropdown-menu {
  font-size: 0.75rem; /* Smaller font size */
  padding: 2px; /* Reduced padding */
  width: auto;
  min-width: 120px; /* Minimum width */
}

/* Dropdown items */
.dropdown-item {
  padding: 2px 8px; /* Reduced padding */
  font-size: 0.75rem; /* Smaller font size */
}

/* Dropdown title text */
.dropdown-toggle .d-block {
  display: block;
  font-size: 0.75rem; /* Smaller title font */
  margin-bottom: 1px; /* Reduced margin */
}

/* Align dropdown link text */
.nav-item.dropdown .nav-link.dropdown-toggle {
  text-align: center; /* Center the text */
  line-height: 1.2; /* Adjust line height */
}

/* Dropdown menu padding and font */
.nav-item .dropdown-menu {
  font-size: 0.85rem; /* Slightly larger font for items */
  padding: 0.5rem; /* Adjusted padding */
}

/* Dropdown item consistency */
.nav-item .dropdown-item {
  padding: 0.5rem 1rem; /* Adjusted padding */
  font-size: 0.85rem; /* Consistent font size */
}

/* Dropdown title consistency */
.nav-link {
  padding: 0.5rem 1rem; /* Consistent padding */
  font-size: 0.9rem; /* Optional: smaller font size */
  line-height: 1.2; /* Adjusted line height */
  text-align: center; /* Center title text */
}

/* Fine-tune spacing between dropdown link and items */
.nav-item.dropdown .dropdown-menu {
  margin-top: 0.1rem; /* Adjust spacing */
}
