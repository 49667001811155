/* yourComponent.css */
.training-list {
    margin-top: 20px; /* Add top margin for spacing */
}
.catalog-title {
    text-align: center;
    font-size: 2.5rem; /* Large, bold font size */
    font-weight: 700; /* Bold text */
    margin-bottom: 1.5rem; /* Space below title */
    color: #2c3e50; /* Stylish dark color */
    text-decoration: none; /* Ensures no underline */
    border: none; /* Removes borders */
    padding: 0; /* Removes padding */

    /* Gradient text styling */
    background: linear-gradient(to right, #4CAF50, #2196F3); /* Gradient background */
    -webkit-background-clip: text; /* Vendor prefix for compatibility */
    background-clip: text; /* Standard property */
    -webkit-text-fill-color: transparent; /* Vendor prefix for transparent text fill */
    text-fill-color: transparent; /* Standard property */
}
.training-catalog {
    padding: 20px;
}