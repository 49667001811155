

/* Container Background and Layout */
.container-xxl {
    background-color: #f8f9fa; /* Light background for better contrast */
    padding-top: 5rem;
    padding-bottom: 5rem;
}

.container {
    max-width: 1200px; /* Limiting container width for better readability */
}

/* Invisible container for the mission paragraph */
.invisible-container {
    background-color: transparent; /* Invisible background */
    padding: 2rem 0; /* Optional padding for spacing */
}

/* Justify text for the mission paragraph */
.styled-paragraph {
    font-size: 1.1rem;
    line-height: 1.7;
    color: #333;
    font-family: 'Arial', sans-serif;
    text-align: justify; /* Justifies the paragraph text */
    margin: 0 auto; /* Center align the paragraph */
}
/* Style for the values section */
.values-section {
    text-align: center;
}

.values-image {
    max-width: 80%; /* Optional: to control the size of the image */
    margin-bottom: 20px;
}

.value-item {
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    text-align: center;
    padding: 20px;
}

.value-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
}

.icon-value {
    font-size: 3rem;
    color: #3498db;
    margin-bottom: 10px;
}

.value-item h4 {
    font-size: 1.25rem;
    color: #333;
    margin-bottom: 10px;
}

.value-item p {
    font-size: 1rem;
    color: #555;
}

/* Ensures that the value items are aligned properly */
.row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
