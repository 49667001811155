/* Paragraph Styling */
.styled-paragraph {
    font-size: 1.1rem; /* Slightly smaller font for readability */
    line-height: 1.7;  /* Spacing between lines for easy reading */
    color: #333;       /* Dark gray text for better contrast */
    font-family: 'Arial', sans-serif; /* Use Arial for a clean look */
}

/* Container Background and Layout */
.container-xxl {
    background-color: #f8f9fa; /* Light background for better contrast */
    padding-top: 5rem;
    padding-bottom: 5rem;
}

.container {
    max-width: 1200px; /* Limiting container width for better readability */
}

/* Image Styling */
img {
    object-fit: cover; /* Ensures the image covers the container nicely */
    border-radius: 10px; /* Rounded corners for the image */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Light shadow to give depth */
}

/* Image hover effect */
.img-fluid {
    max-width: 100%; /* Ensures the image is responsive */
    height: auto;    /* Maintains aspect ratio */
    border-radius: 15px; /* Rounded corners */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out; /* Smooth transition for hover effect */
}

/* Hover effect on image */
.img-fluid:hover {
    transform: scale(1.05); /* Slight zoom effect */
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2); /* Stronger shadow on hover */
}

/* Fade-In Up Animation for text */
.wow.fadeInUp {
    animation: fadeInUp 1s ease-out;
}

@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translateY(50px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Responsive Design */
@media (max-width: 768px) {
    .container-xxl {
        padding: 2rem 0; /* Reduced padding on smaller screens */
    }

    .img-fluid {
        max-width: 100%; /* Ensure the image fills the container */
        height: auto;    /* Keep the image proportions */
    }

    .styled-paragraph {
        font-size: 1rem; /* Slightly smaller font size for mobile screens */
    }
}

/* Container adjustments */
.row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* Responsive adjustments for columns */
@media (max-width: 768px) {
    .col-lg-7, .col-md-6 {
        margin-top: 20px;
    }

    .img-fluid {
        width: 100%; /* Ensure the image fills the container on smaller screens */
    }
}
/* Ensure both images have the same height and width */
.equal-size-img {
    width: 100%;  /* Ensures image fills the container */
    height: 300px;  /* Set a fixed height to make both images the same size */
    object-fit: cover;  /* Ensures the images maintain aspect ratio while covering the space */
}
