/* Training item container with modern design */
.training-item {
    width: 100%;
    padding: 20px;
    border-radius: 15px; /* Add rounded corners */
    border: none; /* Remove default border */
    background: #fff; /* White background for the card */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15); /* Soft shadow effect */
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition */
    margin-top: 20px; /* Add space between the header and the card */
   
    }
    
    .training-item:hover {
      transform: translateY(-10px); /* Slight lift effect on hover */
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2); /* Enhanced shadow on hover */
    }
    
    /* Styling for the title */
    .training-item h2 {
      font-size: 1.2rem; /* Smaller, more elegant font size */
      font-weight: 500; /* Medium weight for a refined appearance */
      color: #007bff; /* Blue color matching the button */
      margin-bottom: 10px; /* Reduced space between title and button */
      text-transform: capitalize; /* Capitalized text for elegance */
      letter-spacing: 0.5px; /* Subtle letter spacing for readability */
    }
    
    /* Styling for the button */
    .training-item .btn {
      background-color: #007bff; /* Primary color for the button */
      color: white; /* White text */
      border: none; /* No border */
      padding: 10px 18px; /* Moderate padding for the button */
      font-size: 0.9rem; /* Smaller font size for the button */
      border-radius: 8px; /* Rounded corners for the button */
      text-transform: uppercase; /* Uppercase for button text */
      font-weight: bold; /* Bold text to stand out */
      transition: background-color 0.3s ease, transform 0.3s ease; /* Smooth transitions */
    }
    
    /* Button hover effect */
    .training-item .btn:hover {
      background-color: #0056b3; /* Darker shade for hover effect */
      transform: translateY(-3px); /* Slight hover lift effect for the button */
    }
    
    /* Flexbox container to align training items */
    .row {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around; /* Spread out items evenly */
    }
    
    /* Each column should have equal width */
    .col-md-3 {
      display: flex;
      justify-content: center; /* Center items horizontally */
      margin-bottom: 20px; /* Space between rows */
    }
    
    /* Responsive design adjustments for smaller screens */
    @media (max-width: 768px) {
      .col-md-3 {
        flex: 0 0 100%; /* Make each item take full width on smaller screens */
      }
    }
    .catalog-title {
      text-align: center;
      font-size: 2rem; /* Large and bold font size */
      font-weight: 700; /* Bold text */
      margin-bottom: 1.5rem; /* Spacing below the title */
      color: #13273a; /* Dark modern color */
      text-decoration: none; /* Removes any underline */
      background: linear-gradient(to right, #555c55, #2196F3); /* Gradient effect */
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent; /* Gradient applies to text only */
      letter-spacing: 1.5px; /* Add slight letter spacing */
      text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2); /* Soft shadow effect */
      border: none; 
  }