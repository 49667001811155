.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.consulting-page-container {
  text-align: left; /* Align text to the left */
  font-family: Arial, sans-serif; /* Consistent font */
  color: #000; /* Black text */
}

.certification-image-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.certification-image {
  max-width: 100%;
  height: auto;
}

.consulting-title {
  text-align: center; /* Center the title */
  font-size: 2rem;
  font-weight: normal;
  margin-top: 20px; /* Space above the title */
  margin-bottom: 30px; /* Space below the title */
  color: #000;
}

.consulting-text {
  line-height: 1.6;
}

.consulting-list {
  list-style-type: disc;
  margin-left: 20px;
}

.consulting-list li {
  margin-bottom: 10px;
}
.page-title {
  text-align: center;
  font-size: 1.5rem; /* Adjusted font size */
  font-weight: bold;
  margin-bottom: 20px;
  color: #333;
}
