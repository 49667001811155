.footer {
    font-family: 'Arial', sans-serif; /* Ensure consistent font across all footer text */
    font-size: 0.9rem; /* Slightly smaller font size for compactness */
}

.footer h5 {
    font-size: 1.1rem;
    font-weight: 600;
}

.footer p, .footer a {
    font-size: 0.9rem;
    line-height: 1.6;
}

.footer a {
    color: #d1d1d1; /* Lighter shade for links */
    text-decoration: none; /* Remove underline */
}

.footer a:hover {
    color: #fff; /* Make links stand out on hover */
}

.footer .btn-social {
    font-size: 1.2rem;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer .btn-social:hover {
    background-color: #fff;
    color: #333;
}

.footer .list-unstyled li {
    margin-bottom: 10px; /* Add space between list items */
}

@media (max-width: 768px) {
    .footer {
        font-size: 0.85rem; /* Smaller font size for mobile */
    }

    .footer .col-lg-3, .footer .col-md-6 {
        text-align: center; /* Center-align text on mobile */
    }
}
.footer .row.g-4 > div {
    margin-bottom: 20px; /* Add space between sections on all viewports */
}

@media (min-width: 768px) {
    .footer .row.g-4 > div {
        margin-bottom: 0; /* Remove extra margin on larger screens for consistent alignment */
    }

    .footer .col-lg-3:not(:last-child) {
        margin-right: 20px; /* Add horizontal space between sections */
    }
}
