/* Ensure the image does not scale or transform on hover */
.slider-container img {
    width: 663px; /* Fixed width for your image */
    height: 81px; /* Fixed height for your image */
    object-fit: cover; /* Maintain the aspect ratio */
    border: none; /* Remove any border around the image */
    box-shadow: none; /* Remove shadow */
    transition: none; /* Ensure no transition happens */
}

/* Prevent any scaling or effects when hovering */
.slider-container img:hover {
    transform: none; /* No scaling or transformation */
    box-shadow: none; /* Remove shadow on hover */
}
