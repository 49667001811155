/* Ensures spacing above and below the title */
.catalog-title {
  text-align: center;
  font-size: 2.5rem; /* Large, bold font size */
  font-weight: 700; /* Bold text */
  margin-bottom: 2rem; /* Adds space below the title */
  margin-top: 0; /* Prevents extra margin above */
  color: #2c3e50; /* Stylish dark color */
  text-decoration: none; /* Ensures no underline */
  border: none; /* Removes borders */
  padding: 0; /* Removes padding */

  /* Gradient text styling */
  background: linear-gradient(to right, #4CAF50, #2196F3); /* Gradient background */
  -webkit-background-clip: text; /* Vendor prefix for compatibility */
  background-clip: text; /* Standard property */
  -webkit-text-fill-color: transparent; /* Vendor prefix for transparent text fill */
  text-fill-color: transparent; /* Standard property */
}

/* Add padding and spacing to the container */
.card-container {
  padding: 20px;
  display: flex;
  flex-direction: column; /* Ensures the title is above the card */
  align-items: center; /* Center-aligns the content */
  gap: 2rem; /* Adds spacing between the title and the card */
}

/* Styling for the card container and shadow */
.card-centered {
  max-width: 600px; /* Adjust max-width to control the card size */
}

/* Adjust card body */
.card-body {
  padding: 20px;
}

/* Button styling */
.btn-primary {
  background-color: #4CAF50;
  border-color: #4CAF50;
  color: white;
}
