/* Additional table styling for a smaller size */
.details-table {
    width: 80%; /* Reduce the width to 80% of the container */
    margin: 20px auto; /* Center the table horizontally */
    border-collapse: collapse; /* Remove space between borders */
  }
  
  .details-table th, .details-table td {
    padding: 8px; /* Decrease padding for a smaller table */
    text-align: left; /* Default text alignment */
    font-size: 14px; /* Decrease font size for smaller appearance */
  }
  
  .details-table th {
    background-color: #f0f0f0; /* Header background */
    color: #333; /* Header text color */
  }
  
  .details-table tbody tr:nth-child(even) {
    background-color: #f9f9f9; /* Zebra striping */
  }
  
  /* Center title in the table */
  .details-title {
    text-align: center; /* Center the text */
    color: #007BFF; /* Title text color */
    font-size: 24px; /* Adjust font size as needed */
    font-weight: bold; /* Bold text */
    padding: 10px; /* Padding around the text */
    background-color: #f9f9f9; /* Background color */
    border-bottom: 2px solid #007BFF; /* Optional: bottom border for emphasis */
    border-radius: 5px; /* Optional: rounded corners */
    width: 100%; /* Ensures the title takes the full width of the table */
  }
  
 
  .back-button-container {
    display: flex;
    justify-content: center;
    margin: 20px 0;
  }
  
  .btn-back {
    background-color: #007BFF;
    color: #fff;
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 5px;
    font-size: 16px;
    transition: background-color 0.3s;
  }
  
  .btn-back:hover {
    background-color: #0056b3;
  }
  .catalog-title {
    text-align: center;
    font-size: 2rem; /* Large and bold font size */
    font-weight: 700; /* Bold text */
    margin-bottom: 1.5rem; /* Spacing below the title */
    color: #13273a; /* Dark modern color */
    text-decoration: none; /* Removes any underline */
    background: linear-gradient(to right, #555c55, #2196F3); /* Gradient effect */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; /* Gradient applies to text only */
    letter-spacing: 1.5px; /* Add slight letter spacing */
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2); /* Soft shadow effect */
    border: none; 
}