/* Overall page styling */
.financement-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh; /* Full height to cover the entire viewport */
  }
  
  /* Centering the content area */
  .financement-content-wrapper {
    flex-grow: 1;
    display: flex;
    justify-content: center; /* Horizontally centering */
    padding: 20px 15px;
  }
  
  /* Centered inner content */
  .financement-content {
    width: 100%;
    max-width: 800px; /* Limit the width for readability */
    background-color: #f9f9f9; /* Light background color */
    border-radius: 10px; /* Rounded corners */
    padding: 20px; /* Internal padding */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    color: #333; /* Text color */
  }
  
  /* Title styling */
  .financement-title {
    text-align: center;
    color: #007BFF;
    font-size: 32px;
    margin-bottom: 20px;
  }
  
  /* Image styling */
  .financement-image {
    display: block;
    max-width: 100%;
    height: auto;
    margin: 20px auto;
    border-radius: 10px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  
  /* Content Heading styling */
  .financement-content h2 {
    color: #05213f;
    font-size: 24px;
    margin-top: 20px;
  }
  
  /* Paragraph styling */
  .financement-content {
    width: 100%;
    max-width: 800px; /* Limit the width for readability */
    background-color: #ffffff; /* Light background color */
    border-radius: 10px; /* Rounded corners */
    padding: 20px; /* Internal padding */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    color: #333; /* Text color */
}
  
  /* Footer note */
  .footer-note {
    margin-top: 20px;
    font-size: 14px;
    color: #555;
  }
  
  /* Media queries for responsive design */
  @media (max-width: 768px) {
    .financement-content {
      padding: 15px;
    }
  
    .financement-title {
      font-size: 28px;
    }
  
    .financement-content h2 {
      color: #4c5258; /* Blue color */
      font-size: 18px; /* Smaller font size */
      margin-top: 20px;
      text-align: left; /* Align to the left */
      font-weight: bold; /* Ensure bold styling for emphasis */
    }
  
    .financement-content p {
      font-size: 14px;
    }
  }
  .page-title {
    text-align: center;
    font-size: 1.5rem; /* Adjusted font size */
    font-weight: bold;
    margin-bottom: 20px;
    color: #333;
  }
  