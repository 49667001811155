/* src/pages/FormationPages/leansixsigma.css */

/* Container for the Lean Six Sigma catalog */
.training-list {
    display: flex; /* Use flexbox for layout */
    flex-wrap: wrap; /* Allow items to wrap to the next line */
    justify-content: center; /* Center items horizontally */
    gap: 20px; /* Space between cards */
}

/* Center the card and adjust its width */
.card-centered {
    max-width: 300px; /* Adjust max width for smaller cards */
    width: 100%; /* Full width within the container */
    padding: 20px;
    border-radius: 15px; /* Add rounded corners */
    border: none; /* Remove default border */
    background: #fff; /* White background for the card */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15); /* Soft shadow effect */
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition */
    display: flex; /* Enable flexbox on card */
    flex-direction: column; /* Align items in a column */
    justify-content: space-between; /* Space out title and button */
    align-items: center; /* Center items horizontally */
}

/* Adjust container minimum height to ensure full page centering */
.min-vh-100 {
    min-height: 100vh;
}

/* Hover effect for the card */
.card-centered:hover {
    transform: scale(1.05) translateY(-5px); /* Slightly enlarge and move up the card */
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2); /* Add a more prominent shadow on hover */
}

/* Card title for consistent padding */
.card-centered .card-title {
    padding: 15px 0;
    font-size: 18px; /* Adjust font size for better appearance */
    font-weight: 600;
    color: #007BFF; /* Title text color */
    text-align: center; /* Center the title */
}

/* Add padding to the button for better alignment */
.card-centered .btn {
    padding: 10px 20px;
    background-color: #007BFF; /* Button background color */
    color: #fff; /* Button text color */
    border: none; /* Remove default border */
    border-radius: 5px; /* Rounded corners for button */
    transition: background-color 0.3s ease; /* Smooth background color transition */
    margin-top: auto; /* Push the button to the bottom */
}

/* Button hover effect */
.card-centered .btn:hover {
    background-color: #0056b3; /* Darker shade on hover */
}

/* Title for the training catalog */
.catalog-title {
    text-align: center; /* Center the title */
    color: #007BFF; /* Title text color */
    font-size: 28px; /* Adjust font size */
    font-weight: bold; /* Bold text */
    padding: 20px; /* Padding around the text */
    background-color: #f9f9f9; /* Background color */
    border-bottom: 2px solid #007BFF; /* Optional: bottom border for emphasis */
    margin: 20px 0; /* Margin for spacing */
}
.certification-note {
    background-color: #f8f9fa;
    padding: 20px;
    margin-top: 20px;
    border-radius: 8px;
    border: 1px solid #dee2e6;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.certification-heading {
    font-size: 1.5rem;
    color: #007bff;
    margin-bottom: 15px;
    text-align: center;
}

.certification-text {
    font-size: 1rem;
    text-align: center;
    color: #343a40;
    line-height: 1.6;
}
.certified-badge {
    color: rgb(19, 35, 65);
    font-weight: bold;
    font-size: 18px;
    display: inline-flex;
    align-items: center;
    margin-left: 10px;
}

.certificate-icon {
    margin-right: 5px;
    color: rgb(224, 193, 17);
}
.details-title {
    font-size: 24px;
    font-weight: bold;
    text-align: left;
    padding-bottom: 10px;
}
.catalog-title {
    text-align: center;
    font-size: 2rem; /* Large and bold font size */
    font-weight: 700; /* Bold text */
    margin-bottom: 1.5rem; /* Spacing below the title */
    color: #13273a; /* Dark modern color */
    text-decoration: none; /* Removes any underline */
    background: linear-gradient(to right, #555c55, #2196F3); /* Gradient effect */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; /* Gradient applies to text only */
    letter-spacing: 1.5px; /* Add slight letter spacing */
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2); /* Soft shadow effect */
    border: none; 
}