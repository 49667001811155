/* Styling for event titles */
.eventTitle {
  font-size: 18px;
  font-weight: bold;
  color: #333;
  cursor: pointer; /* Indicates the title is clickable */
  transition: color 0.2s ease-in-out, text-decoration 0.2s ease-in-out;
}

.eventTitle:hover {
  color: #3e6691; /* Highlight color on hover */
  text-decoration: underline; /* Underline to indicate clickability */
}

/* Styling for event dates */
.eventDate {
  font-size: 14px;
  color: #666;
  margin-left: 10px;
}

/* Ensure proper spacing and layout for the event list */
.event-list-container {
  padding: 20px;
}

.event-list {
  list-style-type: none;
  padding: 0;
}

/* Styling for each event container */
.event-item {
  background-color: #f9f9f9; /* Light background for the container */
  border: 1px solid #ddd; /* Subtle border */
  border-radius: 8px; /* Rounded corners */
  padding: 15px; /* Inner spacing */
  margin-bottom: 20px; /* Spacing between events */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.event-item:hover {
  transform: translateY(-5px); /* Lift effect on hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Stronger shadow on hover */
}

.event-item .event-link {
  display: block;
  text-decoration: none;
}

.event-item .event-title {
  display: flex;
  flex-direction: column;
}

.event-item .event-date {
  margin-top: 5px;
  font-size: 14px;
  color: #888;
}
