/* TrainingDetail.css */
.training-detail {
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #f9f9f9;
}

.training-title {
    font-size: 24px;
    color: #007bff;
    margin-bottom: 15px;
}

.training-description {
    font-size: 16px;
    margin-bottom: 15px;
}

.training-details {
    list-style-type: none;
    padding: 0;
}

.training-detail-item {
    margin-bottom: 10px;
}
/* yourComponent.css */
.training-list {
    margin-top: 20px; /* Add top margin for spacing */
}
